(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$(document).ready(function () {
  var _lottieAnimate = Cookies.get("lottieAnimate");
  $('#loader').show();
  if (_lottieAnimate != "true") {
    var params = {
      container: document.getElementById('hello-bar'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: './assets/json/data.json'
    };
    var anim;
    anim = lottie.loadAnimation(params);
    anim.addEventListener('complete', function () {
      gsap.to($('#loader'), {
        height: 0,
        duration: 0.7,
        ease: "sine.out"
      });
      $('#loader').find('svg').hide();
    });
    anim.setSpeed(1.2);
    Cookies.set("lottieAnimate", "true");
  } else {
    $('#loader').remove();
  }

  //yedek
  //var direction = 1;
  /*var params = {
         container: document.getElementById('testanim'),
         renderer: 'svg',
         loop: false,
         autoplay: false,
         path: './assets/json/data.json'
     };
     var anim;
     anim = lottie.loadAnimation(params);*/
  /* anim.addEventListener('complete', () => { 
  anim.setDirection(-1);
  });*/

  $('#lookPage').fullpage({
    //options here
    autoScrolling: true,
    scrollHorizontally: true,
    navigation: false,
    licenseKey: '14DCC393-7FFC4F67-ABE92807-FFE30C1D',
    //menu:'#menu',
    anchors: ['hello', 'what-we-do', 'what-we-do', 'what-we-do', 'what-we-do', 'work', 'clients', 'medium', 'team', 'contact'],
    scrollingSpeed: 932,
    responsiveSlides: true,
    //sectionsColor: ['#C63D0F', '#1BBC9B', '#7E8F7C', '#1BBC9B'],
    //responsiveWidth: 991,
    //responsiveHeight: 700,
    afterLoad: function afterLoad(origin, destination, direction) {
      //console.log(destination.index);
    },
    onLeave: function onLeave(origin, destination, direction) {
      var width = $(window).width();
      var index = destination.index;
      var anchor = destination.anchor;
      //var cur = anim.currentRawFrame;
      //page-name
      var web_page_name = $('.web-page-name');
      if (destination.anchor == 'what-we-do') {
        gsap.to(web_page_name, {
          top: 50 + '%',
          opacity: 1,
          duration: 1,
          ease: "power1.out"
        });
      } else {
        gsap.to(web_page_name, {
          opacity: 0,
          duration: 1,
          ease: "power1.out",
          onComplete: wTopfunction
        });
      }
      function wTopfunction() {
        web_page_name.css('top', '100%');
      }
      //page-name

      //scroll direction
      var scroll = $('.scroll'),
        scroll_inner = $('.scroll span');
      if (index != 0) {
        gsap.to(scroll, {
          bottom: 60,
          opacity: 1,
          duration: 1,
          ease: "power1.out"
        });
      } else {
        gsap.to(scroll, {
          bottom: 0,
          opacity: 0,
          ease: "slow"
        });
      }
      if (direction == 'down') {
        gsap.to(scroll_inner, {
          bottom: '+=' + 7 + '%',
          ease: 'slow'
        });
      } else {
        gsap.to(scroll_inner, {
          bottom: '-=' + 7 + '%',
          ease: 'slow'
        });
      }
      if (index == 1) {
        gsap.to(scroll_inner, {
          bottom: 62 + '%',
          ease: 'slow'
        });
      } else if (index == 2) {
        gsap.to(scroll_inner, {
          bottom: 55 + '%',
          ease: 'slow'
        });
      } else if (index == 3) {
        gsap.to(scroll_inner, {
          bottom: 48 + '%',
          ease: 'slow'
        });
      } else if (index == 4) {
        gsap.to(scroll_inner, {
          bottom: 41 + '%',
          ease: 'slow'
        });
      } else if (index == 5) {
        gsap.to(scroll_inner, {
          bottom: 34 + '%',
          ease: 'slow'
        });
      } else if (index == 6) {
        gsap.to(scroll_inner, {
          bottom: 27 + '%',
          ease: 'slow'
        });
      } else if (index == 7) {
        gsap.to(scroll_inner, {
          bottom: 20 + '%',
          ease: 'slow'
        });
      } else if (index == 8) {
        gsap.to(scroll_inner, {
          bottom: 13 + '%',
          ease: 'slow'
        });
      } else if (index == 9) {
        gsap.to(scroll_inner, {
          bottom: 0 + '%',
          ease: 'slow'
        });
      }
      //scroll direction

      //var totalFrames = anim.totalFrames*anim.frameRate;
      //narrow-plus && figure
      if (anchor == 'what-we-do') {
        $('.slide-figure').find('i').fadeOut(800);
        gsap.to('.what-we-do figure', {
          opacity: 0,
          top: 100 + '%',
          duration: 2,
          delay: 0.7,
          ease: 'power3.out'
        });
        if (index == 1) {
          gsap.to('.def-icon-narrow-plus', {
            bottom: 'auto',
            top: 25 + '%',
            opacity: 1,
            duration: 1.5,
            ease: 'expo.out'
          });
          gsap.to('.what-we-do .v1', {
            opacity: 1,
            top: 50 + '%',
            duration: 2,
            delay: 0.7,
            ease: 'power3.out'
          });
          /*anim.playSegments([cur, 15], true);
          if (direction=='up') {
          	anim.playSegments([25, 15], true);
          }*/
        }

        if (index == 2) {
          gsap.to('.def-icon-narrow-plus', {
            bottom: 'auto',
            top: 33 + '%',
            opacity: 1,
            duration: 1.5,
            ease: 'expo.out'
          });
          gsap.to('.what-we-do .v3', {
            opacity: 1,
            top: 50 + '%',
            duration: 2,
            delay: 0.7,
            ease: 'power3.out'
          });
          /*anim.playSegments([15, 25], true);
          if (direction=='up') {
          	//anim.setSpeed(0.3);
          	anim.playSegments([110, 25], true);
          }*/
        }

        if (index == 3) {
          gsap.to('.def-icon-narrow-plus', {
            bottom: 'auto',
            top: 33 + '%',
            opacity: 1,
            duration: 1.5,
            ease: 'expo.out'
          });
          gsap.to('.what-we-do .v2', {
            opacity: 1,
            top: 50 + '%',
            duration: 2,
            delay: 0.7,
            ease: 'power3.out'
          });
          /*anim.playSegments([15, 25], true);
          if (direction=='up') {
          	//anim.setSpeed(0.3);
          	anim.playSegments([110, 25], true);
          }*/
        }

        if (index == 4) {
          gsap.to('.def-icon-narrow-plus', {
            bottom: 'auto',
            top: 41 + '%',
            opacity: 1,
            duration: 1.5,
            ease: 'expo.out'
          });
          gsap.to('.what-we-do .v3', {
            opacity: 1,
            top: 50 + '%',
            duration: 2,
            delay: 0.7,
            ease: 'power3.out'
          });
          /*anim.playSegments([25, totalFrames], true);
          if (direction=='up') {
          	//console.log(anim.firstFrame + ' - ' + anim.totalFrames-1);
          	anim.goToAndStop(totalFrames, true);
          	//anim.getDuration(40);
          }*/
        }
      } else {
        $('.slide-figure').find('i').fadeIn(1000);
        gsap.to('.def-icon-narrow-plus', {
          bottom: 'auto',
          top: 41 + '%',
          opacity: 0,
          duration: 2,
          ease: 'expo.out'
        });
        gsap.to('.what-we-do figure', {
          opacity: 0,
          top: 100 + '%',
          duration: 2,
          delay: 0.7,
          ease: 'power3.out'
        });
      }
      //narrow-plus

      //zigzag & Contact-url
      if (index % 2) {
        gsap.to('.def-icon-zigzag', {
          bottom: 90,
          duration: 5,
          ease: 'expo.out'
        });
      } else {
        gsap.to('.def-icon-zigzag', {
          bottom: 127,
          duration: 5,
          ease: 'expo.out'
        });
      }
      if (width <= 991 && anchor != 'hello') {
        gsap.to('.fixed-element', {
          bottom: 100,
          opacity: 0,
          duration: 1.5,
          ease: 'expo.out'
        });
      } else {
        if (anchor != 'contact') {
          gsap.to('.fixed-element', {
            opacity: 1,
            bottom: 0,
            duration: 1.2,
            ease: 'expo.out'
          });
        }
      }
      //zigzag & Contact-url

      //video-player
      var video = $('.video-player'),
        video_plus = video.find('.def-icon-full-plus'),
        video_figure = video.find('figure');
      if (anchor == 'get-to-know-us') {
        gsap.to(video_plus, {
          top: -43,
          opacity: 1,
          duration: 1.2,
          delay: 0.2,
          ease: 'sine.out'
        });
        gsap.to(video_figure, {
          top: 0,
          opacity: 1,
          duration: 1.2,
          delay: 0.5,
          ease: 'sine.out'
        });
      } else if (anchor == 'what-we-do') {
        gsap.to(video_plus, {
          top: 100,
          opacity: 0,
          duration: 1.2,
          delay: 0.2,
          ease: 'sine.out'
        });
        gsap.to(video_figure, {
          top: 200,
          opacity: 0,
          duration: 1.2,
          delay: 0.5,
          ease: 'sine.out'
        });
      } else {
        gsap.to(video_plus, {
          top: -100,
          opacity: 0,
          duration: 1.2,
          delay: 0.2,
          ease: 'sine.out'
        });
        gsap.to(video_figure, {
          top: -200,
          opacity: 0,
          duration: 1.2,
          delay: 0.5,
          ease: 'sine.out'
        });
      }
      //video-player

      //works
      if (anchor == 'work') {
        gsap.to($('.def-icon-arrow'), {
          top: -43,
          opacity: 1,
          duration: 1.2,
          delay: 0.2,
          ease: 'sine.out'
        });
        gsap.to($('.banner figure'), {
          top: 0,
          opacity: 1,
          duration: 1.2,
          delay: 0.5,
          ease: 'sine.out'
        });
      } else {
        gsap.to($('.def-icon-arrow'), {
          top: 100,
          opacity: 0,
          duration: 1.2,
          ease: 'sine.out'
        });
        gsap.to($('.banner figure'), {
          top: 100,
          opacity: 0,
          duration: 1.2,
          ease: 'sine.out'
        });
      }
      //works

      //clients
      var clients_title = $('.clients h3'),
        clients_list = $('.clients ul');
      if (anchor == 'clients') {
        gsap.to('.def-icon-narrow-plus', {
          bottom: 'auto',
          top: 25 + '%',
          opacity: 1,
          duration: 2,
          ease: 'expo.out'
        });
        gsap.to(clients_title, {
          marginTop: 0,
          opacity: 1,
          duration: 1.2,
          delay: 0.2,
          ease: 'sine.out'
        });
        gsap.to(clients_list, {
          marginTop: 0,
          opacity: 1,
          duration: 1.2,
          delay: 0.3,
          ease: 'sine.out'
        });
        gsap.to($('.all-button'), {
          opacity: 1,
          duration: 1.2,
          delay: 0.5,
          ease: 'sine.out'
        });
      } else {
        if (width >= 991) {
          if (direction != 'down') {
            gsap.to(clients_title, {
              marginTop: 50,
              opacity: 0,
              duration: 1.2,
              ease: 'sine.out'
            });
            gsap.to(clients_list, {
              marginTop: 55,
              opacity: 0,
              duration: 1.2,
              ease: 'sine.out'
            });
          } else {
            gsap.to(clients_title, {
              marginTop: 0,
              opacity: 0,
              duration: 0.5,
              ease: 'sine.out'
            });
            gsap.to(clients_list, {
              marginTop: 0,
              opacity: 0,
              duration: 0.5,
              ease: 'sine.out'
            });
          }
          gsap.to($('.all-button'), {
            opacity: 0,
            duration: 1.2,
            ease: 'sine.out'
          });
        }
      }
      //clients

      //medium
      var medium = $('.medium'),
        subject = medium.find('ul li.subject');
      subject.find('a').mouseenter(function () {
        gsap.to($(this).parents('li').find('figure'), {
          top: 50 + '%',
          opacity: 1,
          duration: 0.4,
          ease: 'sine.out'
        });
        subject.css('opacity', 0.4);
        $(this).parents('li').css('opacity', 1);
      });
      subject.mouseleave(function () {
        gsap.to($(this).find('figure'), {
          top: 30 + '%',
          opacity: 0,
          duration: 0.4,
          ease: 'sine.out'
        });
        subject.css('opacity', 1);
      });
      var medium = $('.medium');
      var inner = medium.find('.inner');
      if (anchor == 'medium') {
        gsap.to(medium, {
          marginTop: 0,
          opacity: 1,
          duration: 1.2,
          ease: 'sine.out'
        });
        gsap.to(inner, {
          top: 0,
          opacity: 1,
          delay: 0.4,
          duration: 1.2,
          ease: 'sine.out'
        });
      } else {
        if (anchor == 'team' && direction == 'down') {
          gsap.to(medium, {
            marginTop: -50,
            opacity: 1,
            duration: 1.2,
            ease: 'sine.out'
          });
          gsap.to(inner, {
            top: -50 + '%',
            opacity: 0,
            duration: 1.2,
            ease: 'sine.out'
          });
        } else if (anchor == 'clients' && direction == 'up') {
          gsap.to(medium, {
            marginTop: 0,
            opacity: 1,
            duration: 1.2,
            ease: 'sine.out'
          });
          gsap.to(inner, {
            top: 0 + '%',
            opacity: 0,
            duration: 1.2,
            ease: 'sine.out'
          });
        }
      }
      //medium

      //team
      var team = $('.team');
      if (anchor == 'team') {
        //gsap.to($('.team').find('h4'), {marginTop:0,opacity:1,delay:0.6,duration:1.2,ease:'expo.out'});
        gsap.to($('.team .def-icon-border'), {
          opacity: 1,
          delay: 0.8,
          duration: 1.2,
          ease: 'expo.out'
        });
        if (width >= 991) {
          gsap.to($('.def-icon-fly'), {
            top: -71,
            opacity: 1,
            delay: 0.8,
            duration: 1,
            ease: 'sine.out'
          });
        } else {
          gsap.to($('.def-icon-fly'), {
            top: 60 + '%',
            opacity: 1,
            delay: 0.5,
            duration: 0.8,
            ease: 'sine.out'
          });
        }
      } else {
        gsap.to($('.team .def-icon-border'), {
          opacity: 0,
          duration: 1,
          ease: 'expo.out'
        });
        if (width >= 991) {
          gsap.to($('.def-icon-fly'), {
            top: 10 + '%',
            opacity: 0,
            duration: 1.2,
            ease: 'sine.out'
          });
        } else {
          gsap.to($('.def-icon-fly'), {
            top: 90 + '%',
            opacity: 0,
            delay: 1,
            duration: 1.2,
            ease: 'sine.out'
          });
        }
      }
      //team

      //contact
      var contact = $('.contact');
      if (anchor == 'contact') {
        gsap.to($('.fixed-element'), {
          opacity: 0,
          duration: 1.2,
          ease: 'sine.out'
        });
        gsap.to($('.scroll'), {
          opacity: 0,
          duration: 1.2,
          ease: 'sine.out'
        });
        gsap.to($('.main-contact'), {
          bottom: 0,
          delay: 0.3,
          duration: 1.5,
          ease: 'slow.out'
        });
      } else {
        gsap.to($('.main-contact'), {
          bottom: -100 + '%',
          duration: 1.5,
          ease: 'slow.out'
        });
      }
      //contact

      /*if (destination.anchor=='work') {
      	//gsap.to(video_plus, {top:100,opacity:0,duration:1.2,delay:0.2,ease:'sine.out'});
      	$('.work-information').addClass('animated fadeOutUp');
      }*/
    }
  });

  if ($('#scene,#icons').length > 0) {
    var scene = $('#scene,#icons').get(0);
    var parallaxInstance = new Parallax(scene);
  }

  //home-slide
  $('.carousel').carousel({
    wrap: false
  });
  var owl_work = $('.work-slide .owl-carousel');
  owl_work.owlCarousel({
    items: 1,
    nav: true,
    dots: true,
    transitionStyle: "fade",
    smartSpeed: 1000,
    //fluidSpeed:1000,
    loop: false,
    mouseDrag: true,
    margin: 30,
    navText: '',
    responsive: {
      0: {
        items: 1,
        stagePadding: 40
      },
      768: {
        items: 1
      }
    }
  });
  /*owl.on('mousewheel', '.owl-stage', function (e) {
      if(e.originalEvent.wheelDelta > 0) {
         owl.trigger('prev.owl');
      }
      else{
          owl.trigger('next.owl');
      }
      e.preventDefault();
  });*/

  $('.other-projects .owl-carousel').owlCarousel({
    items: 1,
    nav: true,
    dots: true,
    transitionStyle: "fade",
    smartSpeed: 1000,
    slideBy: 2,
    //fluidSpeed:1000,
    loop: false,
    mouseDrag: true,
    margin: 30,
    navText: '',
    responsive: {
      0: {
        items: 1,
        stagePadding: 20
      },
      768: {
        items: 2
        //stagePadding:40
      }
    }
  });

  //medium mobile
  $('.medium .owl-carousel').owlCarousel({
    items: 1,
    nav: false,
    dots: false,
    transitionStyle: "fade",
    smartSpeed: 700,
    //fluidSpeed:1000,
    loop: false,
    mouseDrag: false,
    margin: 30,
    responsive: {
      0: {
        items: 1,
        stagePadding: 40
      },
      550: {
        items: 1,
        stagePadding: 60
      }
    }
  });

  //medium mobile slide
  $('.customer-reviews .owl-carousel').owlCarousel({
    items: 1,
    nav: true,
    dots: true,
    transitionStyle: "fade",
    smartSpeed: 700,
    loop: false,
    mouseDrag: false,
    margin: 30
  });

  //animation all
  //TweenMax.to('.def-icon-two-border', 10, { rotation: "+=360", repeat:-1, ease: Linear.easeNone, transformOrigin:"50% 50%" });
  /*var tl = new TimelineMax({repeat:-1, repeatDelay:0.5})
  	tl.from(".def-icon-border-bar", 1, {scaleX:0, transformOrigin:"left"})
   		.to(".def-icon-border-bar", 1, {scaleX:0, transformOrigin:"right"}, "reveal")*/

  //menu-toggle
  var header = $('header'),
    menu_open = header.find('.menu-open'),
    menu_close = $('.menu-panel').find('.menu-close'),
    menu_panel = $('.menu-panel');
  var x = false;
  menu_open.click(function (e) {
    e.preventDefault();
    gsap.to('.menu-panel', {
      bottom: 0,
      duration: 1,
      ease: 'power3'
    });
    gsap.to($('.menu-panel > *'), {
      opacity: 1,
      delay: 0.8,
      duration: 1.2,
      ease: 'sine.out'
    });
    //$('html').css('overflow','hidden');
  });

  menu_close.click(function (e) {
    e.preventDefault();
    gsap.to($('.menu-panel > *'), {
      opacity: 0,
      ease: 'sine.out'
    });
    gsap.to('.menu-panel', {
      bottom: 100 + '%',
      delay: 0.5,
      duration: 1,
      ease: 'power4'
    });
    //$('html').css('overflow','auto');
  });

  var nav = menu_panel.find('nav ul li a');
  nav.mouseenter(function () {
    var a_this = $(this);
    nav.css('color', '#4e4cf5');
    a_this.css('color', '#ffffff');
  });
  nav.mouseleave(function () {
    nav.css('color', '#ffffff');
  });

  //page-top
  $('.page-top,.header-logo').click(function () {
    $('html,body').animate({
      scrollTop: 0
    }, 1000);
  });
  function resize() {
    var height = $(window).height();
    var medium = $('.medium,.inner');
    medium.height(height - 302);
  }
  resize();
  $(window).resize(function () {
    resize();
  });
  $.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };
  function blueHeader() {
    var subPageBanner = $('.wrapper .common-banner').outerHeight() - 86;
    if ($(window).scrollTop() >= subPageBanner) {
      header.removeClass('blue-header');
    } else {
      header.addClass('blue-header');
    }
  }
  $(window).on("load scroll", function () {
    var parallaxElement = $(".parallax"),
      parallaxQuantity = parallaxElement.length;
    window.requestAnimationFrame(function () {
      for (var i = 0; i < parallaxQuantity; i++) {
        var currentElement = parallaxElement.eq(i),
          windowTop = $(window).scrollTop(),
          elementTop = currentElement.offset().top,
          elementHeight = currentElement.height(),
          viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
          scrolled = windowTop - elementTop + viewPortHeight;
        currentElement.css({
          transform: "translate3d(0," + scrolled * -0.10 + "px, 0)"
        });
      }
    });
  });
  function detailScrollEvent() {
    //header
    var subPageBanner = $('.wrapper .top-banner'),
      header = $('header'),
      scrollTop = $(window).scrollTop(),
      wireFrame = $('.wireframe'),
      user_interface = $('.user-interface'),
      user_interface_top = $('.user-interface').offset().top,
      user_interface_height = $('.user-interface').height(),
      user_interface_plus = $('.user-interface').offset().top - 421,
      user_interface_calculate = user_interface_top + user_interface_height - 700;
    if ($(window).width() <= 991) {
      user_interface_calculate = user_interface_top + user_interface_height - 400;
    }

    //header
    var PageBannerHeight = $('.wrapper .top-banner').outerHeight() - 86;
    if ($(window).scrollTop() >= PageBannerHeight) {
      header.removeClass('detail-header');
    } else {
      header.addClass('detail-header');
    }

    //section-fixed
    var scrollTop = $(window).scrollTop();
    var wireFrame_top = wireFrame.offset().top;
    if (scrollTop >= wireFrame_top) {
      wireFrame.find('.fix-title').addClass('fixed');
    } else {
      wireFrame.find('.fix-title').removeClass('fixed');
    }
    //section-fixed

    //user_interface backgroundColor
    if (scrollTop >= Math.ceil(user_interface_plus)) {
      user_interface.addClass('color');
      header.addClass('detail-header');
      wireFrame.find('.fix-title').removeClass('fixed');
      $('.this-name').removeClass('colored');
    } else if (scrollTop <= Math.ceil(user_interface_plus)) {
      user_interface.removeClass('color');
      if (!subPageBanner.isInViewport()) {
        header.removeClass('detail-header');
      }
    }
    if (scrollTop >= 88 && scrollTop <= Math.ceil(user_interface_plus)) {
      $('.this-name').addClass('colored');
    } else if (scrollTop <= 88) {
      $('.this-name').removeClass('colored');
    }

    //user_interface section-fixed
    if (scrollTop >= user_interface_top) {
      user_interface.find('.fix-title').addClass('fixed');
    } else if (scrollTop <= user_interface_top) {
      user_interface.find('.fix-title').removeClass('fixed');
    }
    if (scrollTop >= user_interface_calculate) {
      user_interface.removeClass('color');
      header.removeClass('detail-header');
      user_interface.find('.fix-title').removeClass('fixed');
      //$('.highlight-fix-title').hide();
      if ($(window).width() > 991) {
        $('.this-name').css('opacity', 0);
      }
    } else {
      if ($(window).width() > 991) {
        $('.this-name').css('opacity', 1);
      }
    }
    if ($('.highlight').length > 0) {
      var highlight = $('.highlight');
      var highlight_top = $('.highlight').offset().top;
      if (highlight.isInViewport()) {
        user_interface.find('.fix-title').fadeOut();
        if (scrollTop >= highlight_top) {
          highlight.find('.highlight-fix-title').addClass('fixed');
        } else {
          highlight.find('.highlight-fix-title').removeClass('fixed');
        }
        if (scrollTop >= user_interface_calculate) {
          highlight.find('.highlight-fix-title').hide();
        } else {
          highlight.find('.highlight-fix-title').show();
        }
      } else {
        user_interface.find('.fix-title').fadeIn();
      }
    }
  }
  if ($('.common-banner').length > 0) {
    blueHeader();
  }
  if ($('.project-detail').length > 0) {
    detailScrollEvent();
  }
  $(window).scroll(function () {
    if ($('.common-banner').length > 0) {
      blueHeader();
    }
    if ($('.project-detail').length > 0) {
      detailScrollEvent();
    }
  });
  $(window).resize(function () {
    if ($('.common-banner').length > 0) {
      blueHeader();
    }
    if ($('.project-detail').length > 0) {
      detailScrollEvent();
    }
  });

  /*var Scrollbar = window.Scrollbar;
  //options = {damping:0.05}
  Scrollbar.init(document.querySelector('#my-wrapper'),{
  	damping:0.05,
  });*/

  function changeImg(imgNumber) {
    var myImages = ["assets/images/content/slide-1.png", "assets/images/content/slide-2.png", "assets/images/content/slide-3.png", "assets/images/content/slide-4.png"];
    var newImgNumber = Math.floor(Math.random() * myImages.length);
    $('.slide-figure figure img').attr('src', myImages[newImgNumber]);
    setTimeout(function () {
      $('.slide-figure').fadeIn();
    }, 100);
  }
  changeImg();

  //cursor
  cursorMoveFunc();
  function cursorMoveFunc() {
    var e = $("#cursor");
    function t(t) {
      function n() {
        e.find(".cursor__label").text("");
      }
      TweenMax.to(e, .2, {
        left: t.clientX - e.width() / 2,
        top: t.clientY - e.height() / 2
      }), "medium" == $(t.target).data("cursor-type") ? (e.removeClass().addClass("is-medium"), n()) : "big" == $(t.target).data("cursor-type") ? "btn-play" == $(t.target).data("cursor-text") ? (e.removeClass().addClass("is-play").addClass("is-big"), n()) : e.removeClass().addClass("is-view").addClass("is-big") : (e.removeClass(), n());
    }
    $("body,.menu-panel,a").css("cursor", "none");
    $('body a,.owl-nav > div').attr('data-cursor-type', 'big');
    $('.menu-panel,.contact,.common-banner,.direction-bar').mouseenter(function () {
      $('#cursor .cursor__bg').css('backgroundColor', '#ffffff');
    });
    $('.menu-panel,.contact,.common-banner,.direction-bar').mouseleave(function () {
      $('#cursor .cursor__bg').css('backgroundColor', '#0000E6');
    });
    $('header').mouseenter(function () {
      if (!$(this).hasClass('blue-header')) {
        $('#cursor .cursor__bg').css('backgroundColor', '#0000E6');
      } else {
        $('#cursor .cursor__bg').css('backgroundColor', '#ffffff');
      }
    });
    var n = function n() {
      $(window).on("mousemove", t);
    };
    n(), $(window).resize(n);
  }
  //cursor

  var date = new Date();
  var year = date.getFullYear();
  $('span#year').html(year);
});
window.initMap = function () {
  var customMapType = new google.maps.StyledMapType([{
    "elementType": "geometry",
    "stylers": [{
      "color": "#f5f5f5"
    }]
  }, {
    "elementType": "labels.icon",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#616161"
    }]
  }, {
    "elementType": "labels.text.stroke",
    "stylers": [{
      "color": "#f5f5f5"
    }]
  }, {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#bdbdbd"
    }]
  }, {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [{
      "color": "#eeeeee"
    }]
  }, {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#757575"
    }]
  }, {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [{
      "color": "#e5e5e5"
    }]
  }, {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#9e9e9e"
    }]
  }, {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [{
      "color": "#ffffff"
    }]
  }, {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#757575"
    }]
  }, {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [{
      "color": "#dadada"
    }]
  }, {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#616161"
    }]
  }, {
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#9e9e9e"
    }]
  }, {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [{
      "color": "#e5e5e5"
    }]
  }, {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [{
      "color": "#eeeeee"
    }]
  }, {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [{
      "color": "#c9c9c9"
    }]
  }, {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#9e9e9e"
    }]
  }], {
    name: 'Custom Style'
  });
  var customMapTypeId = 'custom_style';
  var myLatlng = {
    lat: 41.10988383119187,
    lng: 29.021713115343758
  };
  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 13,
    center: myLatlng,
    disableDefaultUI: true,
    mapTypeControlOptions: {
      mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId]
    }
  });
  var image = 'assets/images/content/marker.svg';
  var beachMarker = new google.maps.Marker({
    position: myLatlng,
    map: map,
    icon: image
  });
  map.mapTypes.set(customMapTypeId, customMapType);
  map.setMapTypeId(customMapTypeId);
};

},{}]},{},[1]);
